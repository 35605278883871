<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div class="banner-title">大象流程引擎</div>
        <div class="banner-subtitle">
          大象流程引擎具备完整的业务流程设计器及流程管理能力，同时具备表单引擎、权限管理、系统集成等功能，为业务功能快速开发提供技术技术支撑。本产品遵循行业规范，具备良好的易用性、开放性、安全性，可以快速对接三方系统，有效提升人和组织的生产力、提升管理精细度。
        </div>
      </div>
    </div>

    <div class="advantage">
      <div class="type-area">
        <div class="divider">
          <el-divider>系统亮点</el-divider>
        </div>
        <div class="advantage-main">
          <div class="item" v-for="item in advantageList">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="process">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统智能监控各种安全风险状况，有效解决人力成本高、响应不及时等问题。
          </div>
          <div class="dot-plot">智能报警</div>
        </div>
        <div class="process-main">
          <img src="@/assets/images/banner1.png" alt="" />
          <div>
            <div v-for="item in processList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            功能完整的任务推送和提醒机制，赋能高效的工作任务处理。
          </div>
          <div class="dot-plot">车辆监控</div>
        </div>
        <div class="engine-main">
          <div>
            <div v-for="item in engineList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner2.png" alt="" />
        </div>
      </div>
    </div>

    <div class="process task">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            系统智能监控各种安全风险状况，有效解决人力成本高、响应不及时等问题。
          </div>
          <div class="dot-plot">远程视频</div>
        </div>
        <div class="process-main task-main">
          <img src="@/assets/images/banner3.png" alt="" />
          <div>
            <div v-for="item in taskList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine systems">
      <div class="type-area">
        <div class="base-top">
          <div>
            <div class="cut-off"></div>
            功能完整的任务推送和提醒机制，赋能高效的工作任务处理。
          </div>
          <div class="dot-plot">车辆监控</div>
        </div>
        <div class="engine-main system-main">
          <div>
            <div v-for="item in systemList">
              <span>{{ item.title }}</span
              >{{ item.subTitle }}
            </div>
          </div>
          <img src="@/assets/images/banner4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

export default {
  name: "solution", // 解决方案
  data() {
    return {
      advantageList: [
        {
          title: "车辆信息实时监控",
          content: "支持Android、iOS、MacOS、Windows等平台间进行通畅音视频通话",
          img: require("@/assets/images/车辆监控实心.png"),
        },
        {
          title: "自动化智能告警",
          content: "完全私有化部署，音视频通信运行于专有网络中，适应多种场景",
          img: require("@/assets/images/自动告警.png"),
        },
        {
          title: "上下车AI刷脸打卡",
          content: "支持720、1080p高清画质，提供优质稳定的通化体验",
          img: require("@/assets/images/刷脸打卡.png"),
        },
        {
          title: "可视化指挥调度",
          content:
            "支持实时消息通信，支持文字、图片、文件等格式，可进行自定义消息开发",
          img: require("@/assets/images/可视化调度.png"),
        },
        {
          title: "支持多业务系统",
          content:
            "分布式架构模式, 以独立的微服务方式运行, 各业务系统可以直接调用",
          img: require("@/assets/images/音视频留存.png"),
        },
        {
          title: "车辆到站实时提醒",
          content: "开放账号、通话相关API接口，支持新业务场景的功能开发",
          img: require("@/assets/images/到站提醒.png"),
        },
      ],
      processList: [
        {
          title: "碰撞报警",
          subTitle: "支持Web端拖拽各类型控件形成表单，快速实现业务功能",
        },
        {
          title: "超速报警",
          subTitle:
            "结合业务场景对各字段惊醒灵活配置，包括显示样式、操作属性等",
        },
        {
          title: "危险驾驶报警",
          subTitle:
            "表单编辑提交后可以发布成为正式表单，支持保存数据、开启流程等操作",
        },
        {
          title: "路线偏离报警",
          subTitle: "",
        },
        {
          title: "超载报警",
          subTitle: "",
        },
        {
          title: "学生遗落报警",
          subTitle: "",
        },
      ],
      engineList: [
        {
          title: "车辆信息",
          subTitle:
            "地图界面点击车辆即可查看车辆详情信息，包括车牌号、车辆照片、行驶里程、车辆基础信息、关联司机等信息。",
        },
        {
          title: "车辆实时定位",
          subTitle:
            "在地图界面显示所有校车的实时位置，点击可以进行查看详情、查看历史轨迹、下发工作任务等操作。",
        },
        {
          title: "车辆运行状态监控",
          subTitle:
            "管理员可通过列表或地图上的车辆图查看实时状态，包含车辆载客、行驶速度、行驶里程、车内温度、胎压等实时运行状况。",
        },
        {
          title: "运行线路监控",
          subTitle:
            "实时监控校车行进线路的情况，如遇道路施工、事故、灾害天气等情况则实时展示在地图界面。",
        },
      ],
      taskList: [
        {
          title: "视频云存储",
          subTitle:
            "系统自动将校车采集的视频数据存储至云端，供管理员随时追溯问题。",
        },
        {
          title: "远程视频直播",
          subTitle:
            "驾驶舱管理员可以点击地图上的车辆远程查看车内视频监控、行车记录仪的视频画面，视频画面自动存储至云端。",
        },
        {
          title: "视屏回放",
          subTitle:
            "驾驶舱管理员可以回放查看指定车辆内外视频画面，支持截取视频画面单独存证。",
        },
        {
          title: "事件视频追踪",
          subTitle:
            "驾驶舱管理员可以创建驾驶行为事件，指派工作人员进行问题追溯、现场勘查等操作。",
        },
      ],
      systemList: [
        {
          title: "任务列表",
          subTitle:
            "列表形式显示当前用户的任务，支持处理任务、查看流程、查看办理记录。",
        },
        {
          title: "组任务",
          subTitle:
            "用户任务分配直用户组时，系统自动将任务推送至同一用户角色所有用户。",
        },
        {
          title: "消息推送",
          subTitle:
            "新任务流转至用户时支持以多种消息形式提醒，支持消息模板自定义。",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
// .type-area {
//   width: 1180px;
//   margin: 0 auto;
// }
.dot-plot {
  width: 380px;
  height: 137px;
  text-align: right;
  background: url("~@/assets/images/dot-plot.png") no-repeat;
  background-size: 380px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}
.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}
.top-banner {
  height: 580px;
  width: 100%;
  background-image: url("~@/assets/images/banner.png");
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  .banner-text {
    width: 1180px;
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 454px;
    height: 90px;
    color: #b6bfd3;
  }
}
.advantage {
  height: 799px;
  background: #fbfbfb;
  padding-top: 84px;
  &/deep/.el-divider__text {
    background: #fbfbfb;
  }
  .advantage-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -21px;
    .item {
      flex: 0 1 25.1%;
      height: 225px;
      display: flex;
      flex-direction: column;
      margin-top: 107px;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: #1e1e1e;
        line-height: 20px;
        margin: 12px 0 17px 0;
      }
      div {
        width: 260px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: rgba(30, 30, 30, 0.67);
        line-height: 28px;
      }
    }
  }
}
.base-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:first-child {
    display: flex;
    width: 654px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    font-size: 36px;
    line-height: 55px;
    font-weight: 300;
    text-align: justify;
    .cut-off {
      margin-bottom: 22px;
      background: #108aee;
      height: 4px;
      width: 52px;
    }
  }
}
.process {
  height: 804px;
  padding-top: 88px;
  .process-main {
    display: flex;
    justify-content: space-between;
    padding-top: 78.5px;
    img {
      width: 622px;
      height: 413px;
    }
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 30px;
        }
        span {
          color: #1e1e1e;
          line-height: 1px;
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.engine {
  padding-top: 93px;
  height: 803px;
  background: #f5f7f9;
  .engine-main {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    align-items: center;
    img {
      width: 625px;
      height: 360px;
    }
    & > div {
      width: 460px;
      div {
        display: flex;
        flex-direction: column;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #797c80;
        line-height: 26px;
        &:first-child {
          margin-top: 0;
        }
        span {
          color: #1e1e1e;
          font-weight: 500;
          line-height: 20px;
          font-size: 20px;
          margin-bottom: 22px;
        }
      }
    }
  }
}

.task {
  height: 803px;
  .task-main {
    img {
      width: 606px;
      height: 390px;
    }
  }
}
.systems {
  height: 976px;
  .system-main {
    padding-top: 104px;
    & > div {
      div {
        &:first-child {
          margin-top: -20px;
        }
        margin-top: 58px;
      }
    }
    img {
      width: 647px;
      height: 517px;
      margin-left: -45px;
    }
  }
}
</style>
